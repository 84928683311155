import React from "react"
import { Box, Heading } from "@chakra-ui/react";
import SEO from "./SEO";

function PorovnanieBank() {
  return (
    <article>
      <SEO
        metaTitle="Porovnanie bánk"
        metaDesc="Kalkulačka najvýhodnejšej hypotéky"
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <Heading as="h1" textAlign={"center"} my={"16"}>
        Porovnanie bánk
      </Heading>
      <Box mt={8}>
        {/* Embedding the iframe */}
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="width:100%; max-width: 800px; margin:0 auto;">
                              <iframe src="https://www.fingo.sk/banner/version6?inNewWindow=1&cpar=sietKalkulacka&ref=fd10ee8e373b3c1f0f615040a9b754de&utm_medium=sietKalkulacka&utm_source=879-NOCALL&affiliate=879" id="iframeMortgageV6" style="min-height: 640px; width: 100%; border: none; height: 650px" class="iframes" loading="lazy"></iframe>
                            </div>
                            <script>
                              window.addEventListener('message', function (e) { 
                                if (e.data.height && e.data.iframeId) { 
                                  let iframe = document.getElementById(e.data.iframeId); 
                                  iframe.style.height = (e.data.height + 5) + 'px'; 
                                } 
                              }, false);
                            </script>`,
          }}
        />
      </Box>
    </article>
  );
}

export default PorovnanieBank
