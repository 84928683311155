import React from 'react'
import {
  AspectRatio, 
  Box,
  Heading,
  Link,
  List,
  ListItem,
  ListIcon,
  SimpleGrid
} from "@chakra-ui/react";
//import EmailContactForm from "./EmailContactForm";
import EmailContactForm from "./EmailContactForm";
import {
  MdOutlinePermIdentity, 
  MdPhoneAndroid,
  MdFacebook,
} from "react-icons/md";
import SEO from './SEO';

export default function Kontakt() {
  return (
    <section>
      <SEO
        metaTitle="Kontakt - MaxiHypo"
        metaDesc="Podpora pri financovaní bývania, výpočet maximálnej hypotéky, sprostredkovanie úverov, poistenie nehnuteľnosti - kontakt na maxiHypo.sk"
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <Heading as="h1" textAlign={"center"} my={"16"}>
        Kontakt
      </Heading>
      <SimpleGrid
        maxW={"960px"}
        columns={{ sm: 1, md: 2, lg: 2 }}
        spacing={10}
        mx="auto"
        my={4}
      >
        <Box px={4}>
          <Heading as={"h2"} fontSize="2xl" my={8}>
            Kontaktné informácie
          </Heading>
          <List spacing={4}>
            <ListItem fontWeight="bold" color={"primary.500"} mt={8}>
              <ListIcon
                as={MdOutlinePermIdentity}
                boxSize={8}
                border="0"
                borderRadius="200px"
                color={"white"}
                bg={"secondary.500"}
                p="8px"
                verticalAlign={"middle"}
              />
              Ing. Pavel Havran
            </ListItem>
            <ListItem fontWeight="bold" color={"secondary.500"}>
              <Link href="tel:+4210903202390">
                <ListIcon
                  as={MdPhoneAndroid}
                  boxSize={8}
                  border="0"
                  borderRadius="200px"
                  color={"white"}
                  bg={"secondary.500"}
                  p="8px"
                  verticalAlign={"middle"}
                />
                0903 202 390
              </Link>
            </ListItem>
            <ListItem fontWeight="bold" color={"primary.500"}>
              <Link href="https://www.facebook.com/maxihypo">
                <ListIcon
                  as={MdFacebook}
                  boxSize={8}
                  border="0"
                  borderRadius="200px"
                  color={"white"}
                  bg={"secondary.500"}
                  p="8px"
                  verticalAlign={"middle"}
                />
                maxiHypo.sk{" "}
                <span style={{ color: "secondary.500" }}>na facebooku</span>
              </Link>
            </ListItem>
          </List>
          {/* <Heading as={"h3"} fontSize="xl" mt={"8"}>
            Výpočet hypotéky
          </Heading>
          <AspectRatio maxW="560px" ratio={16 / 9} my={"8"}>
            <iframe
              title="Výpočet hypotéky"
              src="https://maxihypo.b-cdn.net/Vypocet_hypoteky.mp4"
              allowFullScreen
            />
          </AspectRatio> */}
        </Box>
        <Box px={4}>
          <Heading as={"h2"} fontSize="2xl" my={8}>
            Pošli mi správu
          </Heading>
          <EmailContactForm />
        </Box>
      </SimpleGrid>
    </section>
  );
}
