//import Hypoteka from "./Uver";
//import Cards from "./UvodCards";
import Hero from "./Hero";

export default function Uvod() {
  return (
    <>
      <Hero />
      {/* <Hypoteka />     
      <Cards /> */}
    </>
  );
}
