import React from "react";
import { Heading,  Box } from "@chakra-ui/react";
import SEO from "./SEO";

function Ochrana() {
  return (
    <article>
      <SEO
        metaTitle="Ochrana osobných údajov"
        metaDesc="Dokument o ochrane osobných údajov"
        name="Maxihypo.sk"
        type="article"
        author="maxihypo"
      />
      <Heading as="h1" textAlign={"center"} my={"16"}>
        Ochrana osobných údajov
      </Heading>
      <Box maxW={"960"} mx="auto" p="20px">
        <div id="content" class="content" role="main">
          <p>
            Kliknutím na príslušné políčko webovej stránky udeľujete stránke
            maxiHypo.sk, ktorej prevádzkovateľom je Pavel Havran, IČO: 52524761
            (ďalej len „maxiHypo.sk“) v súlade s ustanovením § 11 zákona č.
            122/2013 Z. z. o ochrane osobných údajov a o zmene a doplnení
            niektorých zákonov (ďalej len „zákon o ochrane osobných údajov“)
            súhlas na spracúvanie vašich osobných údajov uvedených vo formulári
            po celú dobu existencie webovej stránky, a to na nasledujúci účel:
          </p>

          <ul>
            <li>
              kontaktovanie a doplnenie informácií potrebných k orientačnému
              výpočtu hypotéky a k poskytnutiu služieb od prevádzkovateľa tejto
              internetovej stránky alebo jeho obchodných partnerov.
            </li>
          </ul>
          <p>
            Stránka maxiHypo.sk týmto vyhlasuje, že Vaše osobné údaje použije
            len na vyššie uvedené účely.
          </p>
          <p>
            <strong>Poučenie:</strong>
          </p>
          <p>
            Poskytnutie Vašich osobných údajov je dobrovoľné a stránka
            maxiHypo.sk nemá právny nárok na ich získavanie. Máte právo na
            informácie, odpis a opravu vašich osobných údajov vedených v
            informačnom systéme.
          </p>
          <p>
            Ochrana Vašich osobných údajov sa spravuje ustanoveniami zákona o
            ochrane osobných údajov, pričom Vaše práva sú upravené najmä v § 28
            zákona o ochrane osobných údajov; napr. právo na základe písomnej
            žiadosti požadovať od stránky maxiHypo.sk napr. vo všeobecne
            zrozumiteľnej forme zoznam Vašich osobných údajov, ktoré sú
            predmetom spracúvania, potvrdenie či sú alebo nie sú osobné údaje o
            Vás spracúvané, opravu alebo likvidáciu Vašich nesprávnych,
            neúplných alebo neaktuálnych osobných údajov, ktoré sú predmetom
            spracúvania a pod.
          </p>
          <p>
            Osobné údaje, ktoré poskytnete stránke maxiHypo.sk. prostredníctvom
            formulára musia byť pravdivé, a v prípade ich zmeny ste povinný o
            ich zmene bezodkladne informovať stránku maxiHypo.sk.
          </p>
          <p>
            V prípade neaktuálnosti Vami poskytnutých osobných údajov, po
            splnení účelu, po podaní námietky alebo v prípade odvolania Vášho
            súhlasu so spracúvaním osobných údajov budú tieto osobné údaje
            zlikvidované.
          </p>
          <p>
            Tento súhlas so spracúvaním osobných údajov máte právo kedykoľvek
            odvolať, v dôsledku čoho dôjde k ukončeniu poskytovania služby a k
            výmazu poskytnutých osobných údajov.
          </p>
        </div>
      </Box>
    </article>
  );
}

export default Ochrana;
