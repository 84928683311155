import {
  Box,
  Button,
  Stack,
  Flex,
  Icon,
  Heading,
  VStack,
  useBreakpointValue,
  SimpleGrid,
  Collapse,
  useDisclosure, 
  Link
} from "@chakra-ui/react";
import {
  MdExpandLess,
  MdExpandMore,
} from "react-icons/md";
import backgroundimg from "../static/MaxiHypoHero.webp";

export default function Hero() {
  const { isOpen: isOpenHypoteky, onToggle: onToggleHypoteky } =
    useDisclosure();
  const { isOpen: isOpenPoistenie, onToggle: onTogglePoistenie } =
    useDisclosure();

  return (
    <section>
      <Flex
        w={"full"}
        h={useBreakpointValue({
          base: "70vh",
          sm: "60vh",
          md: "70vh",
          lg: "80vh",
        })}
        bg={"primary.500"}
        backgroundImage={backgroundimg}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
      >
        <VStack
          w={"full"}
          justify={useBreakpointValue({ base: "center", md: "flex-start" })}
          align={useBreakpointValue({ base: "center", md: "flex-start" })}
          px={useBreakpointValue({ base: 4, md: 8, lg: 12 })}
          bgGradient={useBreakpointValue({
            base: "linear(to-r, blackAlpha.700, transparent)",
            md: "linear(to-r, blackAlpha.600, transparent)",
          })}
        >
          <Stack
            maxW={"2xl"}
            align={"flex-start"}
            m={"auto"}
            spacing={useBreakpointValue({ base: 4, md: 6, lg: 8 })}
          >
            {/* <Heading
              as={"h1"}
              color={"white"}
              fontWeight={700}
              lineHeight={1.25}
              textTransform={"upperCase"}
              fontSize={useBreakpointValue({
                base: "xl",
                sm: "2xl",
                md: "3xl",
              })}
              textShadow="1px 1px 0 #00000099"
            >
              Online kalkulačka
            </Heading> */}

            <Heading
              as={"h2"}
              color={"secondary.500"}
              fontWeight={700}
              lineHeight={1.25}
              //textTransform={"lowerCase"}
              fontSize={useBreakpointValue({
                base: "xl",
                sm: "2xl",
                md: "3xl",
              })}
              textShadow="1px 1px 0 #00000099"
              my={0}
            >
              Online kalkulačka, <br />
              bezplatné poradenstvo, <br />
              sprostredkovanie hypotéky a poistenia
            </Heading>
            {/* Two Columns Below Heading */}
            <SimpleGrid
              columns={{ base: 1, sm: 2 }}
              spacing={4}
              w="full"
              pt={{ base: 4, sm: 2 }}
              alignItems="start"
            >
              <Box
                bg="white"
                p={6}
                rounded="md"
                boxShadow="md"
                textAlign="center"
                position="relative"
                mb={4}
                overflow="visible"
              >
                <Heading
                  as={"h3"}
                  color={"white"}
                  fontSize={"lg"}
                  onClick={onToggleHypoteky}
                  cursor={"pointer"}
                  backgroundColor={"primary.600"}
                  borderRadius={6}
                  py={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  Hypotéky
                  <Icon
                    as={isOpenHypoteky ? MdExpandLess : MdExpandMore}
                    ml={2}
                  />
                </Heading>
                <Box
                  position="absolute"
                  top="80%"
                  left={0}
                  right={0}
                  zIndex={1}
                  backgroundColor={"white"}
                  borderRadius={6}
                  px={6}
                  py={4}
                  display={isOpenHypoteky ? "block" : "none"}
                >
                  <Collapse in={isOpenHypoteky} animateOpacity unmountOnExit>
                    <SimpleGrid columns={{ base: 1 }} spacing={2} mt={4}>
                      <Link href="/vypocitaj-si/vysku-uveru/">
                        <Button w={"100%"} colorScheme="secondary">
                          Výpočet podľa príjmu
                        </Button>
                      </Link>
                      <Link href="/vypocitaj-si/vysku-splatky/">
                        <Button w={"100%"} colorScheme="secondary">
                          Výpočet splátky
                        </Button>
                      </Link>
                      <Link href="/vypocitaj-si/porovnanie-bank/">
                        <Button w={"100%"} colorScheme="secondary">
                          Porovnanie bánk
                        </Button>
                      </Link>
                    </SimpleGrid>
                  </Collapse>
                </Box>
              </Box>
              <Box
                bg="white"
                p={6}
                rounded="md"
                boxShadow="md"
                textAlign="center"
                position="relative"
                mb={4}
                overflow="visible"
              >
                <Heading
                  as={"h3"}
                  color={"white"}
                  fontSize={"lg"}
                  onClick={onTogglePoistenie}
                  cursor={"pointer"}
                  backgroundColor={"primary.600"}
                  borderRadius={6}
                  py={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  Poistenie
                  <Icon
                    as={isOpenPoistenie ? MdExpandLess : MdExpandMore}
                    ml={2}
                  />
                </Heading>
                <Box
                  position="absolute"
                  top="80%"
                  left={0}
                  right={0}
                  zIndex={1}
                  backgroundColor={"white"}
                  borderRadius={6}
                  px={6}
                  py={4}
                  display={isOpenPoistenie ? "block" : "none"}
                >
                  <Collapse in={isOpenPoistenie} animateOpacity unmountOnExit>
                    <SimpleGrid columns={{ base: 1 }} spacing={2} mt={4}>
                      <Link
                        target="_blank"
                        href="https://poistenie.fingo.sk/pzp-kalkulacka?ref=fd10ee8e373b3c1f0f615040a9b754de"
                      >
                        <Button w={"100%"} colorScheme="secondary">
                          PZP
                        </Button>
                      </Link>
                      <Link
                        target="_blank"
                        href="https://poistenie.fingo.sk/havarijne-poistenie?ref=fd10ee8e373b3c1f0f615040a9b754de"
                      >
                        <Button w={"100%"} colorScheme="secondary">
                          Havarijné poistenie
                        </Button>
                      </Link>
                      <Link
                        target="_blank"
                        href="https://poistenie.fingo.sk/cestovne-poistenie?ref=fd10ee8e373b3c1f0f615040a9b754de"
                      >
                        <Button w={"100%"} colorScheme="secondary">
                          Cestovné poistenie
                        </Button>
                      </Link>
                      <Link
                        target="_blank"
                        href="https://poistenie.fingo.sk/poistenie-majetku?ref=fd10ee8e373b3c1f0f615040a9b754de"
                      >
                        <Button w={"100%"} colorScheme="secondary">
                          Poistenie domu/bytu
                        </Button>
                      </Link>
                    </SimpleGrid>
                  </Collapse>
                </Box>
              </Box>
            </SimpleGrid>
            {/* <a
              href="https://www.facebook.com/maxihypo"
              target={"blank"}
              rel={"nopener"}
            >
              <Icon
                as={MdFacebook}
                boxSize={8}
                color={"secondary.500"}
                border="0"
                borderRadius="200px"
              />
            </a> */}
          </Stack>
        </VStack>
      </Flex>
    </section>
  );
}
