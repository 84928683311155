import React from "react"
import { Box, SimpleGrid, Link, Icon, Image, Button, Heading, Center } from "@chakra-ui/react";
import { MdFacebook } from "react-icons/md";


function Footer() {
  return (
    <footer>
      <Box>        
        <Box>
          <SimpleGrid
            columns={[1, null, 4]}
            spacing={5}
            maxW={"960px"}
            mx="auto"
          >
            <Box p={"18px 9px"}>
              <Link href={"/"}>
                <Image
                  src="https://maxihypo.b-cdn.net/maxiHypo-logo.png"
                  w={"200px"}
                  h={"30px"}
                  alt="MaxiHypo"
                  m={"18px 0"}
                />
              </Link>
              <Center>
                <Link href="/kontakt">
                  <Button colorScheme="secondary" size="lg" mx={"auto"}>
                    Kontakt
                  </Button>
                </Link>
              </Center>
            </Box>
            <Box p={"18px 9px"}>
              <Heading as={"h3"} fontSize="md" m={"18px 0"}>
                Pôsobím v okresoch:
              </Heading>
              <p>Levice, Zlaté Moravce, Žarnovica</p>
            </Box>
            <Box p={"18px 9px"}>
              <Heading as={"h3"} fontSize="md" m={"18px 0"}>
                Pracovný čas:
              </Heading>
              <p>
                Pondelok - Piatok: <br />
                9:00 - 17:00
              </p>
            </Box>
            <Box p={"18px 9px"}>
              <Heading as={"h3"} fontSize="md" m={"18px 0"}>
                Kontakt:
              </Heading>
              <Link href="tel:+421903202390">+421 903 202 390</Link>
              <Link
                href="https://www.facebook.com/maxihypo"
                target={"blank"}
                rel={"nopener"}
              >
                <Center>
                  <Icon
                    as={MdFacebook}
                    boxSize={8}
                    color={"secondary.500"}
                    border="0"
                    borderRadius="200px"
                  />
                </Center>
              </Link>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            columns={[1, null, 3]}
            spacing={5}
            textAlign="center"
            fontSize={"sm"}
            borderTop="1px solid"
            borderColor={"primary.500"}
            py={"18px"}
            maxW={"960px"}
            mx="auto"
          >
            <Box></Box>
            <Box>
              © maxiHypo.sk 2019 - {new Date().getFullYear()}. Všetky práva
              vyhradené.
            </Box>
            <Box>
              <Link href="/ochrana-osobnych-udajov">
                Ochrana osobných údajov
              </Link>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </footer>
  );
}

export default Footer
